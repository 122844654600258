<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignBdoCardForm"
        ref="assignBdoCardForm"
        class="custom-form"
        @submit.prevent="assign"
      >
        <v-col class="d-flex flex-row-reverse">
          <v-btn
            color="primary"
            class="base-hover mb-2"
            type="submit"
            form="assignBdoCardForm"
            :disabled="!selectedCardExistAndHasPdfFile"
            :loading="isProcessingAssignment"
          >
            Przypisz kartę
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="query"
            placeholder="Wpisz nazwę klienta"
            label="Nazwa klienta"
            class="mb-6"
            outlined
            hide-details
            clearable
            @input="handleSearch"
            @click:clear="handleSearch"
          >
            <template #prepend-inner>
              <Icon
                name="search"
                size="small"
                class="mr-2"
              />
            </template>
          </v-text-field>
          <v-data-table
            hide-default-footer
            class="cards-table"
            :headers="headers"
            :items="parsedBdoCards"
            :items-per-page="-1"
            :loading="isProsessingBdoCards || isProcessingAssignment"
            loading-text="Ładowanie"
            :item-class="(item) => item.id === bdoCardId && 'highlighted-row'"
            @click:row="selectCard"
          >
            <template #[`item.refresh`]="{ item: refreshItem }">
              <td>
                <v-btn
                  elevation="0"
                  color="transparent"
                  size="x-small"
                  @click.stop="fetchBdoPdfs(refreshItem.id)"
                  :disabled="isProsessingBdoCards"
                  icon
                >
                  <v-icon
                    color="#2CB56B"
                    :class="refreshingBdoCardIds.includes(refreshItem.id) && isProcessingAssignment ? 'mdi-spin' : ''"
                    size="20"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-col>

        <embed
          v-if="pdfFileUrl"
          :src="pdfFileUrl"
          type="application/pdf"
          width="100%"
          height="500px"
        >
        <Warning
          v-else-if="bdoCardId"
          message="Brak karty KPO/KPOK do wyświetlenia"
          class="ml-4"
        />
      </v-form>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import rules from '../../utils/validators'
import { dateStringFormat } from '../../utils'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { Course, FlatBdoCard } from '../../models'
import debounce from 'lodash/debounce'

const headers = [
  { text: 'Odśwież', value: 'refresh', sortable: false },
  { text: 'Nr KPO', value: 'cardNumber' },
  { text: 'Firma przekazująca', value: 'senderName' },
  { text: 'MPD przekazującego', value: 'senderBusinessPlaceName' },
  { text: 'Adres MPD przekazującego', value: 'senderBusinessPlaceAddress' },
  { text: 'Wytw. odp.', value: 'wasteGenerating' },
  { text: 'Firma przejmująca', value: 'receiverName' },
  { text: 'MPD przejmującego', value: 'receiverBusinessPlaceName' },
  { text: 'Kod odpadu', value: 'reportedDebrisTypeCode' },
  { text: 'Data', value: 'date' },
  { text: 'Status karty', value: 'cardStatus' }
]

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      headers,
      bdoCardId: null,
      query: '',
      refreshingBdoCardIds: []
    }
  },
  computed: {
    ...mapState({
      dialogItem: state => state.layout.dialog.item,
      isProsessingBdoCards: state => state.bdoCards.isProcessing,
      isProcessingAssignment: state => state.bdoCard.isProcessing,
      bdoCards: state => state.bdoCards.items
    }),
    item() {
      return new Course(this.dialogItem)
    },
    course () {
      return this.item?.swapCourse || this.item
    },
    order () {
      return this.course.order
    },
    pdfFileUrl () {
      return this.bdoCards.find(card => card.id === this.bdoCardId)?.orderBdoFileUrl
    },
    parsedBdoCards() {
      return this.bdoCards.map((card) => ({
        ...card,
        wasteGenerating: card.wasteGenerating ? 'Tak' : 'Nie',
        date: card.cardStatus === 'Zatwierdzona' ? dateStringFormat(card.realTransportTime) : dateStringFormat(card.plannedTransportTime)
      }))
    },
    selectedCardExistAndHasPdfFile() {
      return this.bdoCardId && this.parsedBdoCards.find(card => card.id === this.bdoCardId)?.orderBdoFileUrl
    }
  },
  created () {
    this.query = this.order?.client?.name || this.course?.client?.name || ''
    this.getBdoCards()
  },
  methods: {
    ...mapActions({
      assignOrder: 'bdoCard/assignOrderToBdoCard',
      assignCourse: 'bdoCard/assignCourseToBdoCard',
      updateBdoCard: 'bdoCards/updateBdoCard',
      getBdoCards (dispatch) {
        dispatch('bdoCards/getItems', { params: this.getRequestParams() })
      },
      fetchBdoPdfs (dispatch, id) {
        this.refreshingBdoCardIds.push(id)
        dispatch('bdoCard/fetchBdoPdfs', id).then((data) => {
          this.updateBdoCard(new FlatBdoCard(data))
          this.refreshingBdoCardIds = this.refreshingBdoCardIds.filter(refreshingId => refreshingId !== id)
        })
      },
    }),
    selectCard(card) {
      this.bdoCardId = card.id
    },
    assign () {
      if (this.$refs.assignBdoCardForm.validate()) {
        const params = { courseId: this.course.id, bdoCardId: this.bdoCardId }
        this.assignCourse(params)
          .then(() => { this.afterFormSubmitted('Przypisano kartę') })
      }
    },
    handleSearch: debounce(function () {
      this.getBdoCards()
    }, 700),
    getRequestParams() {
      return {
        assigned: false,
        forAssignment: true,
        filters: {
          query: this.query,
          debrisTypeId: this.order.debrisType.id,
          cardStatuses: ['Zatwierdzona', 'Potwierdzenie wygenerowane'],
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cards-table::v-deep .v-data-table__wrapper {
  height: 360px;
  overflow-y: auto;
  tr td:last-child {
    padding-right: 8px !important;
  }
}

::v-deep .highlighted-row{
  background-color: #eeeeee;
}
</style>
